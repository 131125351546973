<template>
  <div class="row">
    <div class="col-12">
      <c-card title="피해내역" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="tabParam"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="saveAccident"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-md-12 col-lg-6">
            <c-select
              :editable="editable"
              :disabled="disabled"
              type="edit"
              codeGroupCd="IIM_DAMAGE_CLASS_CD"
              itemText="codeName"
              itemValue="code"
              name="damageClassCd"
              label="사고분류"
              v-model="tabParam.damageClassCd"
            ></c-select>
          </div>
          <div class="col-md-12 col-lg-6">
            <c-select
              :editable="editable"
              :disabled="disabled"
              type="edit"
              codeGroupCd="IIM_WOUND_CD"
              itemText="codeName"
              itemValue="code"
              name="woundCd"
              label="상해정도"
              v-model="tabParam.woundCd"
            ></c-select>
          </div>
          <div class="col-md-12 col-lg-6">
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              label="물적피해내역"
              name="materialDamageHistory"
              :rows="3"
              v-model="tabParam.materialDamageHistory">
            </c-textarea>
          </div>
          <div class="col-md-12 col-lg-6">
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              label="인적피해내역"
              :rows="3"
              name="humanDamageHistory"
              v-model="tabParam.humanDamageHistory">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <c-table
          ref="table"
          title="사고자 인적사항 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="tabParam.accidentVictimModelList"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :checkClickFlag="false"
          :editable="editable && !disabled"
          noDataLabel="인적피해일 시 사고자 정보를 입력하세요."
          selection="multiple"
          rowKey="victimId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline>
              <c-btn v-if="editable && !disabled" :showLoading="false" label="사고자 추가" icon="add" @btnClicked="add" />
              <c-btn v-if="editable && !disabled && tabParam.accidentVictimModelList.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="remove" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-12">
        <c-upload 
          :attachInfo="attachInfo"
          label="첨부사진"
          :editable="editable&&!disabled">
        </c-upload>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import { v4 as uuidv4 } from 'uuid';
export default {
  name: 'near-degree-damage',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        materialDamageHistory: '',
        humanDamageHistory: '',
        damageClassCd: null,
        woundCd: null,
        iimAccidentId: '',
        accidentStatusCd: '',
        accidentVictimModelList: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'NEAR_DEGREE_DAMAGE',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'victimName',
            field: 'victimName',
            label: '이름',
            style: 'width:80px',
            align: 'center',
            sortable: true
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:80px',
            align: 'center',
            sortable: true
          },
          {
            name: 'career',
            field: 'career',
            label: '직급',
            style: 'width:60px',
            align: 'center',
            sortable: true
          },
          {
            name: 'insideOutsideTypeCd',
            field: 'insideOutsideTypeCd',
            label: '내/외부',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'Y', codeName: '내부직원' },
              { code: 'N', codeName: '외부직원' },
            ],
            sortable: false,
          },
          {
            name: 'degreeDamage',
            field: 'degreeDamage',
            label: '피해상해정도',
            style: 'width:120px',
            type: "textarea",
            align: 'center',
            sortable: true
          },
          {
            name: 'expectTreatmentDate',
            field: 'expectTreatmentDate',
            label: '예상치료기간',
            type: "date",
            range: true,
            style: 'width:150px',
            align: 'center',
            sortable: true
          },
          {
            name: 'actionMatters',
            field: 'actionMatters',
            label: '조치사항',
            style: 'width:200px',
            type: "textarea",
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '300px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      saveType: 'POST',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd !== 'ISNC000001'
    }
  },
  watch: {
    'tabParam.iimAccidentId'() {
      this.setTaskKey();
    },
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.insertUrl = transactionConfig.sop.iim.accident.near.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.near.update.url;
      // code setting
      this.setTaskKey();
    },
    setTaskKey() {
      let taskKey = this.tabParam && this.tabParam.iimAccidentId ? this.tabParam.iimAccidentId : '';
      this.attachInfo.taskKey = taskKey
    },
    add() {
      this.popupOptions.title = '사고자 검색'; // 사고자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.accidentVictimModelList, {
            victimUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.accidentVictimModelList.splice(0, 0, {
              iimAccidentId: this.tabParam.iimAccidentId,
              victimId: uuidv4(),
              victimUserId: _item.userId,
              victimName: _item.userName,
              birthDate: _item.birthDate,
              age: '',
              career: _item.spotName,
              degreeDamage: '',
              expectTreatmentDate: '',
              actionMatters: '',
              insideOutsideTypeCd: null,
            })
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.tabParam.accidentVictimModelList = this.$_.reject(this.tabParam.accidentVictimModelList, { victimUserId: item.victimUserId })
        })
      }
    },
    saveAccident() {
      if (this.tabParam.iimAccidentId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
            if (_item.expectTreatmentDate) _item.expectTreatmentDate = _item.expectTreatmentDate.join();
          })
          this.tabParam.accidentStatusCd = 'ISNC000001'
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
        if (_item.expectTreatmentDate) {
          _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
        }
      })
    },
  }
};
</script>
